import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Layout from '../components/Layout/Layout'
import LastPosts from '../components/Posts/LastPosts'
import StickyBox from '../components/StickyBox/StickyBox'
import styled from 'styled-components'
import { ArticleContent } from './post.style'
import { color } from '../assets/styles/variables'
import Seo from '../components/Seo/Seo'

const H1 = styled.h1`
font-size: 40px;
color: ${color.primary};
@media screen and (max-width: 768px){
    font-size:28px;
}
`

export default function Default({pageContext}) {
    const heading = pageContext.heading
    const content = pageContext.content
    const seo = pageContext.seo
    return (
        <Layout>
            <Seo title={seo.title} description={seo.metaDesc} keywords={seo.focuskw} pathname={seo.opengraphUrl}/>
            <Container>
                <Row>
                    <Col className="mt-4">
                        <H1>{heading}</H1>
                    </Col>
                </Row>
                <Row>
                    <Col lg={8}>
                        <ArticleContent style={{marginTop: 0}}>
                            <div dangerouslySetInnerHTML={{ __html: content }}/>
                        </ArticleContent>
                    </Col>
                    <Col lg={4}>
                        <StickyBox/>
                    </Col>
                </Row>
                <Row>
                    <LastPosts/>
                </Row>
            </Container>
        </Layout>
    )
}
